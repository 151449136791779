<template>
  <section>
    <top-alternative-section :image="OmnicanalImage" :title="$t('comercio-omnicanal.topAlternative.title')"
      :content="$t('comercio-omnicanal.topAlternative.content')"></top-alternative-section>

    <features-section :title="$t('comercio-omnicanal.features.title')" :description="$t('comercio-omnicanal.features.description')" 
      :items="$t('comercio-omnicanal.features.items')" ></features-section>

    <contact-section></contact-section>
  </section>
</template>

<script>
import TopAlternativeSection from "../sections/TopAlternativeSection.vue";
import OmnicanalImage from "../../assets/omnicanal.jpg";
import FeaturesSection from "../sections/FeaturesSection.vue";
import ContactSection from "../sections/ContactSection.vue";

export default {
  components: {
    TopAlternativeSection,
    FeaturesSection,
    ContactSection
  },
  data() {
    return {
      OmnicanalImage: OmnicanalImage,
    };
  }, 
  metaInfo: {
    title: 'Comercio omnicanal',
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'Aumenta tus ventas con un software capaz de unificar el comercio en tienda, en línea y móvil, brindándote así mayor visibilidad de inventario, datos de ventas, conciliación de pagos y preferencias de tus clientes.' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  }
};
</script>
